<template>
  <Card>
    <template #content>
      <div class="grid">
        <div class="col-6 md:col-6">
          <h4>
            {{
              $route.name == "clients"
                ? $t("routes.clients")
                : $t("users.title")
            }}
          </h4>
        </div>
        <div
          v-if="showAddUser"
          class="flex justify-content-center"
          :class="'col-6 md:col-2'"
        >
          <Button
            class="p-button-warning"
            :label="$t('users.reneawList')"
            @click="updateUsers"
            :loading="updateUsersLoading"
            iconPos="right"
          ></Button>
        </div>
        <div
          class="flex justify-content-end"
          :class="showAddUser ? 'col-6 md:col-2' : 'col-6 md:col-6'"
        >
          <Button v-if="!isMobile" :label="$t('global.export')" @click="exportCSV"></Button>
        </div>
        <div
          v-if="showAddUser"
          class="col-6 md:col-2 flex justify-content-center"
        >
          <Button :label="$t('users.addUser')" @click="viewAddUser" />
        </div>
      </div>
      <DataTable
        :value="users"
        :paginator="true"
        :rows="10"
        :loading="loading"
        :filters="filters"
        dataKey="id"
        ref="tableUsers"
        showGridlines
        class="p-datatable-users"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        :currentPageReportTemplate="$tableString"
        removableSort
        breakpoint="1564px"
        sortField="createdTimestamp"
        :sortOrder="-1"
      >
        <template #header>
          <div>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('global.search')"
              />
            </span>
          </div>
        </template>
        <template #empty> {{ $t("users.noUsersFound") }} </template>
        <Column
          field="username"
          :header="$t('users.username')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.username }}
          </template>
        </Column>
        <Column
          field="role"
          :header="$t('users.role')"
          :sortable="true"
          :exportable="false"
        >
          <template #body="{ data }">
            <div v-for="role in data.roles" :key="role.id">
              <Chip
                :label="$t(`userRoles.${role.name}`)"
                style="font-size: 11px; text-transform: capitalize"
              ></Chip>
            </div>
          </template>
        </Column>

        <Column
          field="createdTimestamp"
          :header="$t('users.creationDate')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ $dayjs(data.createdTimestamp).format("DD/MM/YYYY") }}
          </template>
        </Column>

        <Column field="company" :header="$t('users.company')" :sortable="true">
          <template #body="{ data }">
             <template v-if="data.companyName">
              {{ data.companyName}}
            </template>
            <template v-else>
              {{$t("global.noValue")}}
            </template>
          </template>
        </Column>

        <!-- <Column
          field="registered"
          :header="$t('users.registered')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.registered }}
          </template>
        </Column> -->
        <Column
          field="emailVerified"
          :header="$t('global.emailVerified')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{
              data.emailVerified ? $t("global.active") : $t("global.inactive")
            }}
          </template>
        </Column>
        <Column field="enabled" :header="$t('global.status')" :sortable="true">
          <template #body="{ data }">
            {{ data.enabled ? $t("statuses.active") : $t("statuses.inactive") }}
          </template>
        </Column>
        <Column field="id" :header="$t('users.userId')" :sortable="true">
          <template #body="{ data }">
            <span class="text-right">{{ data.id }}</span>
          </template>
        </Column>
        <Column
          field="Actions"
          :header="$t('global.actions')"
          :exportable="false"
        >
          <template #body="{ data }">
            <div v-if="showViewClientInfo" class="text-center">
              <router-link
                v-if="checkRole(pagesPermissions.clients)"
                :to="{ name: 'clientInformation', query: { userId: data.id } }"
                class="flex justify-content-center align-items-center"
              >
                <span class="mdi mdi-24px mdi-account-box"></span>
              </router-link>
            </div>
            <div v-else class="flex justify-content-evenly">
              <Button
                :icon="!data.enabled ? 'pi pi-check' : 'pi pi-times'"
                class="p-button-rounded p-button-text"
                @click="changeStatus(data)"
              />
              <Button
                :label="$t('global.edit')"
                class="p-button-success"
                @click="editUser(data.id)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Users table",
  props: ["showAddUser", "clients", "callUsers", "showViewClientInfo"],
  emits: ["editUser", "addUser"],
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      loading: false,
      updateUsersLoading: false,
    };
  },
  watch: {
    callUsers(val) {
      if (val) {
        this.getUsers();
      }
    },
  },
  computed: {
    ...mapGetters(["users", "checkRole","isMobile"]),
    ...mapState(["pagesPermissions"]),
  },
  async mounted() {
    await this.getUsers();
    await this.$store.dispatch("roles");
  },
  methods: {
    exportCSV() {
      this.$refs.tableUsers.exportCSV();
      if (this.$route.name == "clients") {
        this.$action("export-clients");
      } else {
        this.$action("export-users");
      }
    },
    editUser(id) {
      this.$emit("editUser", id);
    },
    viewAddUser() {
      this.$emit("addUser");
    },
    async changeStatus(user) {
      let tempObject = { ...user };
      tempObject.enabled = !tempObject.enabled;
      try {
        await this.$store.dispatch("updateUser", tempObject).then(() => {
          this.$action("user-status-update", { targetUsername: user.username });
        });
        this.$notification(
          this.$t("users.updateUser"),
          this.$t("users.statusUpdated")
        );
        user.enabled = !user.enabled;
      } catch (err) {
        // this.$notification(
        //   this.$t("users.updateUser"),
        //   this.$t("global.errorMessage"),
        //   "error"
        // );
      }
    },
    async getUsers() {
      this.loading = true;
      this.$store.commit("emptyUsers");
      if (this.clients) {
        await this.$store.dispatch("userByRole", "client");
      } else {
        await this.$store.dispatch("users");
      }
      await Promise.all(
        await this.users.map(async (el) => {
          this.$store.dispatch("userRoles", el.id);
        })
      );
      this.loading = false;
    },
    async updateUsers() {
      this.updateUsersLoading = true;
      await this.$store.dispatch("updateUserList");
      this.updateUsersLoading = false;
      this.$notification(
        this.$t("users.updateUsersList"),
        this.$t("users.updatedUsersList")
      );
    },
  },
};
</script>
