<template>
  <UserClients
    :showViewClientInfo="true"
    :showAddUser="false"
    :clients="true"
  ></UserClients>
</template>

<script>
import UserClients from "../Users/UsersTable.vue";

export default {
  name: "Clients",
  components: {
    UserClients,
  },
  mounted() {
    this.$action("clients-page-view");
  }
};
</script>
